import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Subsidiary Brands`}</h2>
    <p>{`Mosflor Group is exporting flowers from various farms including their own premium quality roses from `}{`[RdC]`}{`. `}</p>
    <p>{`To offer greater consistency with their box designs they wanted to develop alternative brands for the other farms each with a tiered approach to quality. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e6ad3dea4e1609c66106b3ec9a90952d/bcec7/boxes_1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQCAwX/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAGGjFKOaVOluCs/IAv/xAAeEAACAAYDAAAAAAAAAAAAAAABAgADBAUSIREiI//aAAgBAQABBQJhjDyMVWnLLL9Z1bpA2rcIuB78mP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABwQAAICAgMAAAAAAAAAAAAAAAABAjESIRBBYf/aAAgBAQAGPwIyyWltCadkYqrFuxE32Jecf//EABoQAQEBAAMBAAAAAAAAAAAAAAERACExUYH/2gAIAQEAAT8hVRTKtTRMaSC9aTHFvD3HPmpJTiYPmMnmMC6d/9oADAMBAAIAAwAAABDX1z3/xAAYEQEAAwEAAAAAAAAAAAAAAAABABARQf/aAAgBAwEBPxACGpozlf/EABcRAAMBAAAAAAAAAAAAAAAAAAEQEUH/2gAIAQIBAT8QpK1f/8QAHBABAQACAwEBAAAAAAAAAAAAAREAIUFRsXGh/9oACAEBAAE/ECiAlpdYJiIqPwzTVxLy/I+vRsPDEY1JnTb+zFvACB1TIuAG9DV8MZxCn1W+YBEDP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Brand design",
            "title": "Brand design",
            "src": "/static/e6ad3dea4e1609c66106b3ec9a90952d/4b190/boxes_1.jpg",
            "srcSet": ["/static/e6ad3dea4e1609c66106b3ec9a90952d/e07e9/boxes_1.jpg 200w", "/static/e6ad3dea4e1609c66106b3ec9a90952d/066f9/boxes_1.jpg 400w", "/static/e6ad3dea4e1609c66106b3ec9a90952d/4b190/boxes_1.jpg 800w", "/static/e6ad3dea4e1609c66106b3ec9a90952d/bcec7/boxes_1.jpg 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      